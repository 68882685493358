$fonts:
        'Gotham Thin' '../Aggrid/fonts/gotham/thin/gotham-thin-webfont' 100 normal 'gothamthin',
        'Gotham Light' '../Aggrid/fonts/gotham/light/gotham-light-webfont' 200 normal 'gothamlight',
        'Gotham Light Italic' '../Aggrid/fonts/gotham/light/gotham-lightitalic-webfont' 200 italic 'gothamlight_italic',
        'Gotham Book' '../Aggrid/fonts/gotham/book/gotham-book-webfont' 300 normal 'gothambook',
        'Gotham Book Italic' '../Aggrid/fonts/gotham/book/gotham-bookitalic-webfont' 300 italic 'gothambook_italic',
        'Gotham Medium' '../Aggrid/fonts/gotham/medium/gotham-medium-webfont' 500 normal 'gothammedium',
        'Gotham Medium Italic' '../Aggrid/fonts/gotham/medium/gotham-mediumitalic-webfont' 500 italic 'gothammedium_italic',
        'Gotham Bold' '../Aggrid/fonts/gotham/bold/gotham-bold-webfont' 700 normal 'gothambold',
        'Gotham Bold Italic' '../Aggrid/fonts/gotham/bold/gotham-bolditalic-webfont' 700 italic 'gothambold_italic';

@each $font in $fonts {
  @font-face {
    font-family: nth($font, 1);
    src: url(nth($font, 2) + '.eot');
    src: url(nth($font, 2) + '.eot?#iefix') format('embedded-opentype'),
    url(nth($font, 2) + '.woff2') format('woff2'),
    url(nth($font, 2) + '.woff') format('woff'),
    url(nth($font, 2) + '.ttf') format('truetype'),
    url(nth($font, 2) + '.svg#' + nth($font, 5)) format('svg');
    font-weight: nth($font, 3);
    font-style:  nth($font, 4);
  }
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: nth($font, 1), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      src: url(nth($font, 2) + '.svg#' + nth($font, 5)) format('svg');
    }
  }
}

$font-thin: "Gotham Thin", sans-serif;
$font-light: "Gotham Light", sans-serif;
$font-light-italic: "Gotham Light Italic", sans-serif;
$font-regular: "Gotham Book", sans-serif;
$font-italic: "Gotham Book Italic", sans-serif;
$font-medium: "Gotham Medium", sans-serif;
$font-medium-italic: "Gotham Medium Italic", sans-serif;
$font-bold: "Gotham Bold", sans-serif;
$font-bold-italic: "Gotham Bold Italic", sans-serif;

.catalog-controls__controls-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 10px;
  color: #01366B;

  .catalog-controls__searchbar {
    position: relative;
    height: 30px;
    margin: 10px;
    display: flex;
    align-items: center;
    padding: 15px 10px;
    border: 1px solid #01366B;
    border-radius: 20px;
    background: #FFF;
    font-family: $font-medium, sans-serif;

    i {
      color: #65676B;
    }

    .aggrid-searchbar__wrapper input {
      border: none;
    }
  }

  .catalog-controls__v-separator {
    width: 0;
    height: 31px;
    border: 0.5px solid #01366B;
    margin: 0 10px;
  }

  .catalog-controls__tab {
    height: 32px;
    position: relative;
    display: flex;
    font-size: 12px;
    align-items: center;
    margin: 10px;
    border: 1px solid #01366B;
    border-radius: 4.5px;
    cursor: pointer;
    user-select: none;
    background: #FFF;
    font-family: $font-medium, sans-serif;
    svg {
      width: 17px;
      height: 17px;
      margin-right: 5px;
      fill: #01366B;
    }

    i {
      font-size: 20px;
      margin-right: 5px;
    }

    input[type="checkbox"] {
      width: 20px;
      min-width: 20px;
      height: 20px;
      margin-right: 10px;
      margin-left: 0;
      appearance: checkbox;
    }

    .catalog-controls__tab-notif {
      // width: 25px;
      // height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      // position: absolute;
      // top: 0;
      // right: 0;
      // transform: translateX(35%) translateY(-35%);
      // background: #01366B;
      // border-radius: 100%;
      // color: #FFF;
      border-radius: inherit;
      background: none !important;
      color: #01366B;
      padding-left: 5px;
    }

    .catalog-controls__tab-notif__promo {
      // background: #FAEA60;
      // color: #C94A03;
    }

    .product-section-control__wrapper {
      min-width: 175px;
      font-family: $font-medium;
      font-size: 12px;
      height: 300px;
      display:flex;
      position: relative;
      .spinner-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .submenu-item__name {
        text-transform: capitalize;
      }
      .icon-assortment {
        color: rgb(237, 221, 12);
      }
    }
    .dd-panel-wrapper {
      min-width: 100%;
    }
  }
}
