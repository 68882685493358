.order-list__container {
  padding-bottom: 30px;
  padding-top: 20px;

  .order-list__breadcrum {
    padding: 2px 26px;
  }

  .order-list__content {
    width: calc(100% - 56px);
    margin: 0 auto;

    .invoice-order-list__table-controls {
      display: flex;
      align-items: center;
      padding: 25px 0 10px 0;
      .invoice-order-list__table-controls__button-container {
        display: flex;
        align-items: center;
      }

      .invoice-order-list__button {
        height: 35px;
        padding: 7px 15px;
        cursor: pointer;
        box-shadow: rgb(0, 0, 0) 0 0 1px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        margin-right: 20px;
        border: 1px solid rgb(1, 54, 107);
        i {
          margin-right: 5px;
          font-size: 20px;
        }
      }

      .invoice-order-list__order-button {
          background: rgb(1, 54, 107);
          color: rgb(255, 255, 255);
      }

      .invoice-order-list__preorder-filter {
        ul {
          list-style: none;
          padding: 0;
          display: flex;
          margin: 0;
          li {
            margin-right: 25px;
            label{
              display: flex;
              align-items: center;
              font-size: 13px;
              color: #01366b;
              i {
                margin-right: 5px;
                &:before {
                  font-size: 13px;
                }
              }
              input[type="checkbox"] {
                width: 14px;
                min-width: 14px;
                height: 14px;
                margin-right: 10px;
                margin-left: 0;
                appearance: checkbox;
              }
            }
          }
        }
      }
      .invoice-order-list__search-container {
        position: relative;
        margin-right: 60px;
        svg {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .search-box {
        height: 30px;
        display: flex;
        align-items: center;
        padding: 15px 10px;
        border-radius: 4.5px;
        border: 0;
        background: #fff;
        min-width: 222px;
        border: 1px solid #01366b;
      }
    }

    .order-list__label {
      font-size: 10px;
      width: min-content;
      border-radius: 4.5px;
      display: flex;
      justify-content: center;
      padding: 5px;
      font-weight: 600;
      white-space: nowrap;
    }

    .order-list__approved-label {
      background: #FADA80;
    }

    .order-list__delivered-label {
      background: #2AA952;
      color: #FFF;
    }

    .order-list__deleted-label {
      background: #CCC;
      color: #FFF;
    }

    .react-table__wrapper {
      border: 0;
      border-radius: 3px;
      max-height: calc(100vh - 345px);
      overflow: auto;
      box-shadow: none;
      margin-top: 10px;

      .col-container {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 15px;
        .type {
          padding-left: 8px;
          padding-top: 6px;
          img {
            width: 45px;
            height: auto;
          }
        }
      }
      .react-table__row-counter {
        background: #dbdee7;
        color: #01366B;
        font-weight: 600;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        background-color: #fefefe;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ccc;
      }

      .order-list__total-container {
        display: flex;
        color: #01366B;
        white-space: nowrap;
      }

      .order-list__row-action__container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-weight: 600;
        color: #01366B;
        fill: #01366B;

        .order-list__row-action__separator {
          width: 1px;
          height: 30px;
          background: #CCC;
          margin: 0 10px;
        }

        .order-list__row-action__action-container {
          display: flex;
          span {
            display: flex;
            align-items: center;
          }

          .order-list__row-action__renew {
            cursor: pointer;
            margin-left: 15px;

            svg {
              margin-right: 3px;
            }
          }
        }
      }

      .order-list__label-container {
        display: flex;
        align-items: center;

        i {
          margin-right: 10px;
        }

        .temp-icon {
          &:before {
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
          }
        }
      }

      table {
        border: 0;
        box-shadow: none;

        .commercial-order__table-product-quantity {
          height: 29px;
          width: 50px;
          padding: 5px;
          border: 1px solid #01366b;
          box-shadow: 0 0 1px #000;
          border-radius: 4px;
          text-align: center;
        }

        thead {
          color: #01366B;

          th {
            white-space: nowrap;
          }
        }

        tbody {
          background: #FFF;

          .icon-bdc {
            font-size: 29px;
            cursor: pointer;
          }

          tr {
            cursor: pointer;
            &:hover {
              background: rgb(249, 250, 251);
            }

            td {
              padding: 9px 14px;
            }
          }

          .react-table__no-result {
            cursor: default;
          }
        }
      }
    }
  }
}
