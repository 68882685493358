$fonts:
        'Gotham Thin' '../fonts/gotham/thin/gotham-thin-webfont' 100 normal 'gothamthin',
        'Gotham Light' '../fonts/gotham/light/gotham-light-webfont' 200 normal 'gothamlight',
        'Gotham Light Italic' '../fonts/gotham/light/gotham-lightitalic-webfont' 200 italic 'gothamlight_italic',
        'Gotham Book' '../fonts/gotham/book/gotham-book-webfont' 300 normal 'gothambook',
        'Gotham Book Italic' '../fonts/gotham/book/gotham-bookitalic-webfont' 300 italic 'gothambook_italic',
        'Gotham Medium' '../fonts/gotham/medium/gotham-medium-webfont' 500 normal 'gothammedium',
        'Gotham Medium Italic' '../fonts/gotham/medium/gotham-mediumitalic-webfont' 500 italic 'gothammedium_italic',
        'Gotham Bold' '../fonts/gotham/bold/gotham-bold-webfont' 700 normal 'gothambold',
        'Gotham Bold Italic' '../fonts/gotham/bold/gotham-bolditalic-webfont' 700 italic 'gothambold_italic';

@each $font in $fonts {
  @font-face {
    font-family: nth($font, 1);
    src: url(nth($font, 2) + '.eot');
    src: url(nth($font, 2) + '.eot?#iefix') format('embedded-opentype'),
    url(nth($font, 2) + '.woff2') format('woff2'),
    url(nth($font, 2) + '.woff') format('woff'),
    url(nth($font, 2) + '.ttf') format('truetype'),
    url(nth($font, 2) + '.svg#' + nth($font, 5)) format('svg');
    font-weight: nth($font, 3);
    font-style:  nth($font, 4);
  }
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: nth($font, 1), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      src: url(nth($font, 2) + '.svg#' + nth($font, 5)) format('svg');
    }
  }
}

$font-thin: "Gotham Thin", sans-serif;
$font-light: "Gotham Light", sans-serif;
$font-light-italic: "Gotham Light Italic", sans-serif;
$font-regular: "Gotham Book", sans-serif;
$font-italic: "Gotham Book Italic", sans-serif;
$font-medium: "Gotham Medium", sans-serif;
$font-medium-italic: "Gotham Medium Italic", sans-serif;
$font-bold: "Gotham Bold", sans-serif;
$font-bold-italic: "Gotham Bold Italic", sans-serif;


$border-radius: .20rem !default;
$border-radius-sm: .17rem !default;
$enable-caret: false;

$primary: #01366b;
$success: $primary;
$secondary: #e0e0e0;
$border-color: #eaedf3;
$body-color: #3e3f42;
$body-bg: #fbfbfd;

// company_sign color
$format-icon-bg-light-gray: lighten($secondary, 5%);
$mini-market: $primary;
$supermarket: lighten(#ffc107, 25%);

// @import '~bootstrap/scss/bootstrap';

// Ag Grid restyle
$default-background: white;
$odd-row-background-color: $default-background;
$header-background-color: $body-bg;
$input-border-color: #CFD4D9;

$active: $primary;

$alt-background: lighten($primary, 50%);
$selected-color: lighten($primary, 35%);

$header-height: 30px;
$row-height: 44px;
$font-family: $font-regular;
$icons-path: '~ag-grid-community/src/styles/ag-theme-balham/icons/';

@import '~ag-grid-community/src/styles/ag-grid.scss';
@import '~ag-grid-community/src/styles/ag-theme-balham/sass/legacy/_ag-theme-balham-v22-compat.scss';

.aggrid__dot {
  height: 10px;
  width: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  display: block;
  float: left;
  margin-right: 10px;
}

.aggrid__quantity-container {
  display: flex;

  .product-quantity__substitut-button {
    height: 35px;
    padding: 7px;
    margin: 5px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
}

.product-favorite__heart-container {
  display: flex;
  align-items: center;
  margin-right: 5px;

  svg {
    cursor: pointer;
    fill: $primary;
  }
}

.orange_background {
  background-color: orange;
}

.red_background {
  background-color: red;
}

.green_background {
  background-color: green;
}

.grey_background {
  background-color: grey;
}

.ag-theme-balham {

  font-family: $font-medium;

  .ag-pivot-mode-panel {
    display: none;
  }

  .quantity-wrap {
    display: flex;
    align-items: center;
  }

  .quantite-icon {
    float: left;
  }

  .input-quantite-cadre {
    display: block;
    float: left;
  }

  .ag-header-cell-menu-button {
    float: left;
    opacity: 1 !important;
    margin-right: 0.25rem;
  }

  .footer-function-cell-active {
    background-color: $selected-color;
  }

  .ag-cell-focus, .ag-cell {
    border: none !important;
  }

  .ag-side-bar {
    background-color: transparent;

    .ag-icon-columns {
      background: url('./icons/columns.svg')
    }

    .ag-icon-filter {
      background: url('./icons/filter.svg')
    }

    .ag-side-button-button {
      color: white;
      background: #01366b;
      margin-bottom: 0.5rem;
      border-top-left-radius: .25rem!important;
      border-bottom-left-radius: .25rem!important;
    }

    .ag-side-button-label {
      writing-mode: vertical-rl;
    }
  }

  .ag-floating-bottom {
    overflow: visible !important;
    border-top: 2px solid #eaedf3;
  }

  .ag-side-bar {
    border-right: 1px solid #b7b7b8;
  }

  .ag-theme-balham .ag-row:not(.ag-row-first) {
    border-width: 0;
  }

  .ag-header-cell-text {
    text-transform: uppercase;
  }

  .ag-header {
    font-weight: 400;
    background-color: #eaedf3;
    border-bottom: 1px solid #01366b;
  }

  .ag-floating-filter-body input {
    border-radius: 2px;
  }

  .ag-header-cell {
    border: 1px solid white;
  }

  .ag-cell {
    text-transform: capitalize;
    border-bottom: 1px solid $border-color;

    &:hover button.open-entity-profile {
      opacity: 1
    }
  }

  .image-cell {
    background-color: #FFF;
  }

  .ag-row {
    font-weight: 500;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    &:hover button.open-entity-profile {
      display: inline;
    }
  }

  .ag-row-selected {
    background-color: #FBFBFD;
    border-color: #01366b88;
  }

  .ag-row-hover {
    background-color: #FBFBFD;
    border-color: #01366b88;

    .image-cell {
      background-color: #FFF;
    }
  }

  footer {
    width: 100%;
    text-align: right;
    margin-top: 4px;
    margin-right: 10px;
    padding-right: 10px;
  }
}
