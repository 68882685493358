.order-detail__container {
  width: calc(100vw - 60px);
  margin: 0 auto;
  padding-top: 20px;
  .order-detail__page-header {
    margin-bottom: 50px;
  }
  .order-detail__page-content {
    .order-detail__order-number {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      strong {
        color: #01366B;
        font-weight: bold;
      }
    }
    .order-detail__order-info {
      display: flex;
      & > div {
        padding: 10px 20px;
        margin: 10px 15px;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

        &:first-child {
          margin-left: 0;
        }
      }
      .order-detail__order-info__type {
        width: 50px;
        padding: 10px 30px;
        align-items: center;
        border-radius: 3px;
        justify-content: space-around;
        i {
          &:before {
            width: 25px;
            height: 25px;
            line-height: 25px;
            font-size: 13px;
          }
        }

        .order-detail__order-info__type-icon {
          i {
            &:before {
              font-size: 19px;
            }
          }
        }
      }
      .order-detail__order-info__price-container {
        padding: 10px 40px;
        align-items: center;
        justify-content: space-around;
        white-space: nowrap;

        .order-detail__order-info__price {
          font-size: 24px;
          font-weight: 700;
          color: #01366B;
        }
        .order-detail__order-info__package {
          span {
            font-weight: bold;
          }
        }
        .nbContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          .nb {
            font-weight: bold;
          }
          img {
            width: 45px;
            margin-left: 10px;
            margin-right: 10px;
          }
        }
      }
      .order-detail__order-info__validator {
        .order-detail__order-info__validator-name {
          margin-bottom: 10px;
        }
        .order-detail__validator__date-container {
          display: flex;
          div:first-child {
            margin-right: 10px;
          }
        }
        .order-detail__validator__underline {
          margin-top: 5px;
          border-bottom: 1px solid #65676B;
          font-weight: 600;
        }
      }
      .order-detail__order-info__invoice {
        font-weight: 600;
        .order-detail__order-info__invoice-title {
          margin-bottom: 10px;
        }
        .order-detail__order-info__invoice-number {
          color: #00A6FF;
        }
      }
      .order-detail__order-info__actions {
        justify-content: space-around;
        color: #00A6FF;
        font-weight: 700;
        background: none;
        box-shadow: none;

        span {
          cursor: pointer;
          display: flex;
          align-items: center;
          i, svg {
            font-size: 18px;
            margin-right: 5px;
            height: 18px;
            width: 18px;
          }
        }
      }
    }
    .order-detail__order-info__btn {
      svg {
        width: 26px !important;
        margin-left: -3px;
      }
    }
    .react-table__wrapper {
      max-height: calc(100vh - 208px);
      overflow: auto;
      border: 0;
      box-shadow: none;
      border-radius: 3px;

      .order-detail__react-table__img {
        width: 45px;
        height: 45px;
      }

      .order-detail__react-table__quantity {
        font-weight: 600;
        color: #01366B;
        white-space: nowrap;
      }

      .order-detail__react-table__total {
        white-space: nowrap;
        color: #01366B;
        font-size: 18px;
        font-weight: bold;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        background-color: #fefefe;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ccc;
      }

      table {
        border: 0;
        box-shadow: none;

        thead {
          background: #01366B1A;
          color: #01366B;
        }

        tbody {
          background: #FFF;

          .icon-bdc {
            font-size: 29px;
            cursor: pointer;
            margin-right: 15px;
          }

          tr {
            &:hover {
              background: rgb(249, 250, 251);
            }

            td {
              padding: 5px 24px;
            }
          }

          .react-table__no-result {
            cursor: default;
          }
        }
      }
    }
  }
}
