.invoice-detail__container {
  width: calc(100vw - 60px);
  margin: 0 auto;
  padding-top: 20px;
  .invoice-detail__page-header {
    margin-bottom: 50px;
  }
  .invoice-detail__page-content {
    .invoice-detail__invoice-number {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      strong {
        color: #01366B;
        font-weight: bold;
      }

      .invoice-detail__order-link {
        color: #00A6FF;
        cursor: pointer;
      }
    }
    .invoice-detail__invoice-info {
      display: flex;
      & > div {
        padding: 10px 20px;
        margin: 10px 15px;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);

        &:first-child {
          margin-left: 0;
        }
      }
      .invoice-detail__invoice-info__type {
        width: 50px;
        padding: 10px 30px;
        align-items: center;
        border-radius: 3px;
        justify-content: space-around;
        min-height: 110px;
        i {
          &:before {
            width: 25px;
            height: 25px;
            line-height: 25px;
            font-size: 13px;
          }
        }

        .invoice-detail__invoice-info__type-icon {
          i {
            &:before {
              font-size: 19px;
            }
          }
        }
      }
      .invoice-detail__invoice-info__price-container {
        padding: 10px 40px;
        align-items: center;
        justify-content: space-around;
        white-space: nowrap;

        .invoice-detail__invoice-info__price {
          font-size: 24px;
          font-weight: 700;
          color: #2AA952;
        }
        .invoice-detail__invoice-info__package {
          span {
            font-weight: bold;
          }
        }
      }
      .invoice-detail__invoice-info__date-container {
        display: flex;
        div:first-child {
          margin-right: 10px;
        }
        .invoice-detail__invoice-info__date {
          margin-top: 5px;
          border-bottom: 1px solid #65676B;
          font-weight: 600;
        }
      }
      .invoice-detail__invoice-info__credit {
        font-weight: 600;
        .invoice-detail__invoice-info__credit-title {
          margin-bottom: 10px;
        }
        .invoice-detail__invoice-info__invoice-number {
          color: #00A6FF;
        }

        ul {
          list-style: none;
          padding: 0;
          font-weight: normal;
        }
      }
      .invoice-detail__invoice-info__actions {
        justify-content: flex-start;
        padding-top: 20px;
        color: #00A6FF;
        font-weight: 700;
        background: none;
        box-shadow: none;

        span {
          cursor: pointer;
          display: flex;
          align-items: center;
          i, svg {
            font-size: 18px;
            margin-right: 5px;
            height: 18px;
            width: 18px;
          }
        }
      }
    }
    .invoice-detail__credit-request-warning {
      background: #F3CC8C;
      border: 1px solid #F5A623;
      border-radius: 6px;
      padding: 5px 20px;
      font-weight: 600;
      margin: 10px 0;

      svg {
        position: relative;
        top: 2px;
        margin-right: 5px;
      }
    }
    .react-table__wrapper {
      max-height: calc(100vh - 510px);
      overflow: auto;
      border: 0;
      box-shadow: none;
      border-radius: 3px;
      margin-top: 15px;

      .invoice-detail__react-table__img {
        width: 45px;
        height: 45px;
      }

      .invoice-detail__react-table__quantity {
        font-weight: 600;
        color: #01366B;
        white-space: nowrap;
      }

      .invoice-detail__react-table__total {
        white-space: nowrap;
        color: #01366B;
        font-size: 18px;
        font-weight: bold;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        background-color: #fefefe;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ccc;
      }

      table {
        box-shadow: none;

        thead {
          background: #01366B1A;
          color: #01366B;
        }

        th {
          background: #dbdee7;
          border-right: 1px solid #fff;
        }
        th:last-child {
          margin-right: 0;
        }

        tbody {
          background: #FFF;

          .icon-bdc {
            font-size: 29px;
            cursor: pointer;
            margin-right: 15px;
          }

          tr {
            &:hover {
              background: rgb(249, 250, 251);
            }

            td {
              padding: 5px 24px;
            }

            .invoice-detail__react-table__credit-button {
              cursor: pointer;
              color: #00A6FF;
              font-weight: 600;
            }

            .invoice-detail__react-table__credit-button-disabled {
              cursor: not-allowed;
              color: #CCC;
              font-weight: 600;
            }
          }

          .react-table__no-result {
            cursor: default;
          }
        }
      }
    }
  }
}
