/* global style */

* {
  box-sizing: border-box;
}
*:before {
  box-sizing: inherit;
}
*:after {
  box-sizing: inherit;
}
input {
  -webkit-appearance: none;
}

html,
body {
  width: 100%;
}
html {
  height: 100%;
}
body {
  margin: 0px;
  padding: 0;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  color: #4a4a4a;
  background: #F0F2F5;
}
h1,
h2,
h3,
h4,
h5,
h6,
h7,
figure {
  padding: 0px;
  margin: 0px;
}
h1 {
  font-size: 20px;
}
h2 {
  font-size: 18px;
}
h3 {
  font-size: 16px;
}
h4 {
  font-size: 14px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 12px;
}
h7 {
  font-size: 10px;
}
p {
  font-size: 14px;
}

input[type='number'] {
  box-shadow: none;
  -moz-appearance: textfield;
}
input[type='number']::placeholder {
  color: #00a6ff;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.red {
  color: red;
}

.clearfix:after {
  display: block;
  content: "";
  clear: both;
}

/******************************************/
/***************** Styles *****************/
/******************************************/

.light {
  font-weight: 300;
}
.regular {
  font-weight: 500;
}
.regular-italic {
  font-style: italic;
  font-weight: 500;
}
.bold {
  font-weight: 700;
}
.bold-italic {
  font-style: italic;
  font-weight: 700;
}

/******************************************/
/***************** Alerts *****************/
/******************************************/
.alert-danger {
  border: 1px solid rgba(208, 2, 27, 0.2);
  border-radius: 10px;
  background-color: #f9d9dd;
  color: #d0021b;
  padding: 12px;
  margin: 20px 0;
  position: relative;
  text-align: left;
}

.alert-success {
  border: 1px solid rgba(13, 161, 2, 0.8);
  border-radius: 10px;
  word-break: break-word;
  background-color: #ddf9d9;
  color: #0da102;
  padding: 10px;
  width: 100%;
  margin: 10px 0;
}

/******************************************/
/***************** Apps *****************/
/******************************************/
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  -webkit-animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

/******************************************/
/************ Layout **********************/
/******************************************/
.navigation {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  max-width: 100vw;
}

.navigation--header {
  background: #ffffff;
}

.content {
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
  background: #F0F2F5;
}

/******************************************/
/************ passwordRecovery ************/
/******************************************/

.password-recovery__background {
  background-image: url('../images/Connexion.png');
  height: 100vh !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.password_recovery__form {
  background-color: white;
  border-radius: 15px;
  padding: 19px 0;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.password-recovery__errors .alert-danger:before,
.password-change__errors .alert-danger:before {
  content: '\e90b';
  font-family: icomoon;
}
.password-recovery__connection-form a {
  text-decoration: none;
}
.password-recovery__connection-form {
  width: 280px;
  background-color: white;
  border-radius: 15px;
  text-align: center;
  padding: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.password-confirm__container {
  background-color: white;
  border-radius: 15px;
  text-align: center;
  padding: 20px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.password-confirm__btn-connect {
  letter-spacing: 1.06px;
  line-height: 16px;
  font-weight: bold;
  font-size: 12px;
  padding: 11px 20px;
}
.password-recovery-conexion__text-label {
  color: #01366b;
  position: relative;
  top: 22px;
  margin-bottom: 40px;
  font-size: 16px;
}
.password-recovery__text-label {
  color: #01366b;
  font-size: 16px;
  line-height: 19px;
  padding: 21px 20px;
}
.password-confirm__text-label {
  color: #01366b;
  font-size: 16px;
  line-height: 19px;
}
.password-confirm__text-logo {
  color: #01366b;
  margin-top: 0;
  font-size: 14px;
}

.password-recovery__button-container {
  display: flex;
}

.password-confirm__container .password-confirm__label {
  font-size: 14px;
  width: 100%;
  margin: 0;
  position: relative;
  top: 11px;
  padding: 5px 7px 0px 7px;
  height: 33px;
}
.password-all {
  width: 280px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}
input:-internal-autofill-previewed,
input:-internal-autofill-selected {
  color: rgba(255, 0, 0, 0.42) !important;
}
.password-confirm__label {
  width: calc(100% - 18px);
  margin: 0 9px;
  border: none;
  outline: 0;
  color: #4a4a4a;
  font-family: Montserrat;
  font-size: 16px;
  letter-spacing: 0.12px;
  line-height: 18px;
  border-bottom: 0.5px solid rgba(120, 120, 120, 0.6);
  padding: 8px 0 8px 10px;
}

.password_recovery__form .password-recovery__btn {
  display: block;
  margin: 28px auto 0 auto;
  padding: 12px 15px;
  font-weight: bold;
  letter-spacing: 1.06px;
  line-height: 16px;
  display: block;
}
.password-all .password-confirm__btn-connect {
  display: block;
  width: 144px;
  margin: 45px auto 0 auto;
  padding: 11px 20px;
}
input.password-confirm__label.repeat {
  top: 25px;
  padding-bottom: 10px;
}
.hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
  width: 80%;
}

.passwword-recovery__success-text {
  padding-top: 40px;
  color: #01366b;
  font-family: Montserrat;
  font-size: 16px;
}

.color-primary-button {
  background-color: white;
  height: 40px;
  width: 40px;
}
.password_recovery__icon .password_recovery__icon-color {
  color: #29d24e;
}
.titre {
  margin-top: 40px;
  height: 18px;
  width: 119px;
  color: #01366b;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.password-confirm__logo {
  width: 33%;
  padding-bottom: 7px;
}
.password_recovery__icon {
  color: #ffffff;
  background-color: #f5f5f5;
  width: 40px;
  margin: auto;
  border-radius: 50%;
  text-align: center;
  padding: 10px 0;
}

.password_recovery__success-form {
  width: 280px;
  background-color: white;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 42px 20px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}

/******************************************/
/************ Login ***********************/
/******************************************/
.login__background {
  background-image: url('../images/background.png');
  height: 100vh !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.login__form {
  width: 300px; /* Not 280, otherwise user's being blocked error will not render nicely (text is justified) */
  /* Making it centered */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.alert-danger i {
  display: inline-block;
  vertical-align: middle;
}
.alert-danger span {
  display: inline-block;
  vertical-align: top;
  width: calc(100% - 20px);
  padding-left: 10px;
  font-weight: 500;
}
.login__container {
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.login__container form {
  min-height: 205px;
}
.login__container-Logo {
  text-align: center;
  height: 100px;
}
.login__logo-text {
  color: #01366b;
  line-height: 18px;
}
.login__logo {
  width: 33%;
  padding-bottom: 7px;
}
.login__container-label {
  color: #01366b;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  margin-top: 20px;
  height: 50px;
}

.login__icon {
  color: #01366b;
  font-weight: normal;
  font-size: 15px;
  padding: 5px;
}
.login__form-input {
  border: none;
  color: #4a4a4a;
  width: 100%;
  font-family: Montserrat;
  font-size: 16px;
  letter-spacing: 0.12px;
  line-height: 18px;
  border-bottom: 0.5px solid rgba(120, 120, 120, 0.6) !important;
  height: 35px;
  padding-left: 7px;
  margin-top: 5px;
  font-weight: 600;
  outline: none;
}
.login__form-link {
  color: #01366b;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  text-align: right;
  text-decoration: none;
  margin-top: 5px;
  margin-top: 10px;
  display: block;
  margin-bottom: 15px;
}
.login__container .login__form-button {
  padding: 11px 15px;
  width: 144px;
  margin: 0 auto;
  display: block;
}
.login__form-button--label {
  letter-spacing: 1.06px;
  line-height: 16px;
  font-weight: bold;
  font-size: 12px;
}
.login__errors {
  margin-top: 20px;
}
.hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}
.text-label-success {
  padding-top: 80px;
  padding-right: 15px;
  padding-left: 15px;
  color: #01366b;
  font-family: Montserrat;
  font-size: 16px;
}
.icon-check {
  padding-top: 60px;
  padding-right: 115px;
  padding-left: 115px;
}
.color-primary-button {
  background-color: white;
  height: 40px;
  width: 40px;
}
.icon-color {
  color: green;
}
.form1 {
  width: 280px;
  height: 311px;
  text-align: center;
  background-color: white;
  border-radius: 20px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}
.form2 {
  width: 280px;
  height: 180px;
  background-color: white;
  border-radius: 20px;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}
.titre {
  margin-top: 40px;
  height: 18px;
  width: 119px;
  color: #01366b;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.icon-btn {
  color: #ffffff;
  background-color: #f5f5f5;
  border-radius: 50%;
  text-align: center;
  padding: 10px 0;
  width: 40px;
}

/* Dashboard global style*/

.home-page__section {
  background-color: #f5f5f5;
}
.home__section-prop {
  padding-bottom: 20px;
  padding-top: 25px;
  padding-left: 20px;
  width: 100%;
}
.home__section-label {
  color: #01366b;
  display: inline-block;
  vertical-align: middle;
}
.home__section-link {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  color: #787878;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  margin-left: 25px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .login__form-input {
    border: none;
    color: #4a4a4a;
    width: 100%;
    font-family: Montserrat;
    font-size: 16px;
    letter-spacing: 0.12px;
    line-height: 18px;
    border-bottom: 0.5px solid rgba(120, 120, 120, 0.6) !important;
    height: 35px;
    margin-top: 5px;
    font-weight: 600;
    outline: none;
  }
}
/******************************************/
/************ Input style checkbox ********/
/******************************************/
/* label {
	color: #4A4A4A;
	font-size: 14px;
	font-weight: bold;
	line-height: 18px;
	padding-right: 20px;
	position: relative;
	padding-left: 35px;
	cursor: pointer;
 }
input[type="checkbox"] {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: 1px solid rgba(120,120,120,0.54);
}
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
	left: 7px;
	top: 2px;
	width: 5px;
	height: 12px;
	border: solid #01366B;
	border-width: 0 2px 2px 0px;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
input[type="checkbox"]:checked ~ .checkmark:after {
	display: block;
} */
/******************************************/
/************ Input style Radio ********/
/******************************************/
.block__radio {
  display: inline-block;
  vertical-align: middle;
  padding-right: 40px;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
}
.block__radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.block__radio .check {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  border: 1px solid #4a4a4a;
  cursor: pointer;
}
.block__radio .check:after {
  top: 3px;
  left: 3px;
  border-radius: 100%;
  background: #01366b;
  content: '';
  position: absolute;
  display: none;
  padding: 6px;
}
.block__radio input:checked ~ .check:after {
  display: block;
}
/******************************************/
/************ Select style ****************/
/******************************************/
select {
  background-color: #fff;
}
.bloc__select {
  position: relative;
}
/* .bloc__select:after {
	content: "";
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 6px 4px 0 4px;
	border-color: rgba(120,120,120,0.6) transparent transparent transparent;
	line-height: 0px;
	top: 50%;
	position: absolute;
	right: 0;
	background-image:  url("../images/dropdown.jpg");
} */

.bloc__select::after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  font-size: 9px;
  width: 20px;
  text-align: center;
  vertical-align: middle;
  pointer-events: none;
  color: #787878;
}

.bloc__select select {
  border-bottom: 1px solid rgba(120, 120, 120, 0.6) !important;
  border: 0;
  padding: 11px;
  color: #787878;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  outline: none;
  background-image: url('../images/dropdown.jpg');
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

select option {
  background: #f5f5f5;
  color: #787878;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

/******************************************/
/************* Price cell *****************/
/******************************************/

.price-cell {
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  color: #404040;
  font-family: "Gotham bold", sans-serif;
}

.price-cell.has-discount {
  color: #525252;
}

.price-cell > div {
  margin-bottom: 0 !important;
}

.price-cell__unity span,
.price-cell__package span {
  white-space: nowrap;
}

.price-cell__unity .price,
.price-cell__package .price {
  padding-right: 6px;
}

.price-cell__unity {
  color: #01366b;
}

.price-cell__unity > .is-discounted,
.price-cell__package > .is-discounted {
  color: #EF4444;
  text-decoration: line-through;
}

.price-cell__unity > .is-normal,
.price-cell__package > .is-normal {
  color: #1E3A8A;
  font-family: "Gotham medium", sans-serif;
}

.price-cell__unity > .is-discounted > span,
.price-cell__package > .is-discounted > span {
  color: #737373;
}

.price-cell__unity .icon-promo,
.price-cell__package .icon-promo {
  margin-left: 4px;
}

/******************************************/
/************ Input  style ****************/
/******************************************/
input[type='text'],
input[type='email'],
input[type='number'] {
  border: 0;
  border-bottom: 1px solid rgba(120, 120, 120, 0.6);
  /* padding: 11px; */
  outline: 0;
  /* color: #787878;
	font-family: Montserrat;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px; */
}

/******************************************/
/*********** Miscellaneous ****************/
/******************************************/

.nowrap {
  white-space: nowrap;
}

@media screen and (max-width: 320px) {
  .content {
    margin-top: 72px;
  }
}

/******************************************/
/************ Animations ****************/
/******************************************/

.fade-in {
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-with-translate-y {
  animation-name: fadeInWithTranslateY;
  animation-duration: 0.8s;
  animation-fill-mode: forwards;
}

@keyframes fadeInWithTranslateY {
  from {
    transform: translateY(10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
