$fonts:
        'Gotham Thin' '../fonts/gotham/thin/gotham-thin-webfont' 100 normal 'gothamthin',
        'Gotham Light' '../fonts/gotham/light/gotham-light-webfont' 200 normal 'gothamlight',
        'Gotham Light Italic' '../fonts/gotham/light/gotham-lightitalic-webfont' 200 italic 'gothamlight_italic',
        'Gotham Book' '../fonts/gotham/book/gotham-book-webfont' 300 normal 'gothambook',
        'Gotham Book Italic' '../fonts/gotham/book/gotham-bookitalic-webfont' 300 italic 'gothambook_italic',
        'Gotham Medium' '../fonts/gotham/medium/gotham-medium-webfont' 500 normal 'gothammedium',
        'Gotham Medium Italic' '../fonts/gotham/medium/gotham-mediumitalic-webfont' 500 italic 'gothammedium_italic',
        'Gotham Bold' '../fonts/gotham/bold/gotham-bold-webfont' 700 normal 'gothambold',
        'Gotham Bold Italic' '../fonts/gotham/bold/gotham-bolditalic-webfont' 700 italic 'gothambold_italic';

@each $font in $fonts {
  @font-face {
    font-family: nth($font, 1);
    src: url(nth($font, 2) + '.eot');
    src: url(nth($font, 2) + '.eot?#iefix') format('embedded-opentype'),
    url(nth($font, 2) + '.woff2') format('woff2'),
    url(nth($font, 2) + '.woff') format('woff'),
    url(nth($font, 2) + '.ttf') format('truetype'),
    url(nth($font, 2) + '.svg#' + nth($font, 5)) format('svg');
    font-weight: nth($font, 3);
    font-style:  nth($font, 4);
  }

  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: nth($font, 1), -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      src: url(nth($font, 2) + '.svg#' + nth($font, 5)) format('svg');
    }
  }
}

$font-thin: "Gotham Thin", sans-serif;
$font-light: "Gotham Light", sans-serif;
$font-light-italic: "Gotham Light Italic", sans-serif;
$font-regular: "Gotham Book", sans-serif;
$font-italic: "Gotham Book Italic", sans-serif;
$font-medium: "Gotham Medium", sans-serif;
$font-medium-italic: "Gotham Medium Italic", sans-serif;
$font-bold: "Gotham Bold", sans-serif;
$font-bold-italic: "Gotham Bold Italic", sans-serif;

.ag-list__container {
  width: calc(100% - 40px);
  height: 100%;
  margin: 0 auto;

  .ag-list__sub-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    height: 100%;

    .ag-list__wrapper {
      display: table-cell;
      vertical-align: top;
      padding-top: 0;
      flex-grow: 1;
    }
  }
}

.ag-list__notice {
  font-family: $font-light;
  text-align: center;
  font-size: 14px;
  margin-bottom: 24px;
}

.ag-list__title {
  font-family: $font-bold;
  text-align: center;
  font-size: 16px;
  margin-bottom: 16px;
}

.ag-theme-custom-list {
  font-family: $font-medium;
  font-size: 12px;
  width: calc(100%);

  .ag-root-wrapper {
    width: 100%;
    margin: 0 auto 0 0;
  }

  .ag-list-quantity-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .product-quantity__substitut-button {
      padding: 7px;
      margin-bottom: 10px;
      border-radius: 5px;
    }

    .ag-list__cell-wrapper__total-cell {
      margin-left: 5px;
    }
  }

  .ag-body-viewport {
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 10px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      height: 6px;
      width: 6px;
      background-color: #fefefe;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #ccc;
    }
  }

  .ag-center-cols-container {
    width: 100% !important;
  }

  .ag-header {
    display: none;
  }

  .ag-row {
    width: 100%;
    outline: 20px solid transparent;
    background: #FFF;
    border-radius: 20px;
    box-sizing: border-box;
    border: 5px solid #F0F2F5;

    &:hover {
      box-shadow: inset 0 0 0 2px #65676B;
    }
  }

  .ag-row-selected {
    box-shadow: inset 0 0 0 2px #65676B;
  }

  .ag-cell {
    padding-left: 15px;
    background: none;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    white-space: pre-wrap;
    overflow: initial;
  }

  .img-cell__img {
    width: 70px !important;
    height: 70px !important;
  }

  .ag-cell-focus,
  .ag-cell {
    border: none !important;
    outline: none !important;
  }

  .ag-body-horizontal-scroll {
    display: none;
  }

  .ag-react-container {
    width: 100%;
  }

  .ag-list__cell-wrapper {
    .ag-list__cell-wrapper__price-wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    div {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .type-cell {
      text-transform: capitalize;

      i {
        font-size: 9px;
        margin-right: 5px;

        &:before {
          width: 15px;
          height: 15px;
          line-height: 15px;
        }
      }
    }
  }

  .ag-list__image-cell__wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;

    .ag-list__image-cell__type-icon-container {
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-25%, -25%);

      i::before {
        width: 20px;
        height: 20px;
        line-height: 20px;
      }
    }
  }

  footer {
    display: none;
  }

  .favorite-cell__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    i,
    svg {
      margin: 2.5px 0;
    }

    i[icon="icon-new"] {
      padding-left: 1.5px;
      font-size: 17.5px;
    }
  }
}

.ag-list__product-card-container {
  min-width: 30%;
  max-width: 30%;
  display: table-cell;
  margin-left: 5px;
  width: 30% !important;

  .ag-list__product-card-container__border {
    width: 100%;
    overflow: auto;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 2px solid #65676B;
    position: relative;
    height: calc(var(--vh, 1vh)*100 - 210px);
  }
}

.product-card__no-product {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}