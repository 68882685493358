$degressive-prices__background: #F0F2F5;

.degressive-price {
  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__popin {
    background-color: $degressive-prices__background !important;
    box-shadow: 0 0.375rem 0.875rem rgba(0, 0, 0, 0.2); // 6px = 0.375rem, 14px = 0.875rem
    border-radius: 0.5rem; // 8px = 0.5rem
    padding-bottom: 0.625rem; // 10px = 0.625rem
  }

  &__link {
    position: relative;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem; // 8px = 0.5rem
    font-size: 0.75rem; // 12px = 0.75rem
    font-weight: 700;
    color: #EA580C;
    text-decoration: underline;
    border: 0 none;
    background: none;
    padding: 0 0 0.25rem; // 4px = 0.25rem
    text-align: left;

    &:hover {
      cursor: pointer;
    }
  }

  &__table {
    width: 100%;
    background-color: $degressive-prices__background;
    border-radius: 0.375rem;

    th,
    td {
      text-align: left;
    }

    th {
      color: #000000;
      font-weight: 900;
      padding: 0.625rem 1rem; // 10px = 0.625rem, 16px = 1rem
      white-space: nowrap;
    }

    td {
      color: #484848;
      font-weight: 600;
      padding: 0.125rem 1rem; // 2px = 0.125rem, 16px = 1rem
    }
  }
}