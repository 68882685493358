@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon/icomoon.eot?k8z223');
  src: url('../fonts/icomoon/icomoon.eot?k8z223#iefix') format('embedded-opentype'),
    url('../fonts/icomoon/icomoon.ttf?k8z223') format('truetype'),
    url('../fonts/icomoon/icomoon.woff?k8z223') format('woff'),
    url('../fonts/icomoon/icomoon.svg?k8z223#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-radio-button--uncheked:before {
  color: rgba(120, 120, 120, 0.54);
  content: '\ea56';
}

.icon-check-ok:before {
  content: '\e912';
}

.icon-plus:before {
  content: '\e93a';
}
.icon-minus:before {
  content: '\e953';
}
.icon-new:before {
  color: #d0021b;
  content: '\e931';
}

.icon-user-outline:before {
  color: #4a4a4a;
  content: '\e94b';
}
.icon-dry:before {
  background-color: #eddd0c;
  color: #ffffff;
  content: '\e91a';
  height: 30px;
  width: 30px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
  border-radius: 100%;
}

.icon-time:before {
  color: #01366b;
  content: '\e947';
}

.icon-apple:before {
  border-radius: 50%;
  background-color: #35e687;
  color: #ffffff;
  content: '\e922';
  height: 30px;
  width: 30px;
  display: inline-block;
  line-height: 30px;
  text-align: center;
}
.icon-snow:before {
  color: #ffffff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: #63c8ff;
  content: '\e914';
  display: inline-block;
  line-height: 30px;
  text-align: center;
}

.icon-promo .path1:before {
  content: '\e933';
  color: #fcea37;
}
.icon-promo .path2:before {
  content: '\e954';
  color: #cd242e;
  margin-left: -1em;
}
.icon-promo .path3:before {
  content: '\e960';
  color: #cd242e;
  margin-left: -1em;
}
.icon-promo .path4:before {
  content: '\E962';
  color: #cd242e;
  margin-left: -1em;
}
.icon-promo .path5:before {
  content: '\e963';
  color: #cd242e;
  margin-left: -1em;
}

.icon-less-circle:before {
  color: #01366b;
  content: '\e92b';
}

.icon-add-circle:after {
  color: #01366b;
  content: '\e901';
}

.icon-arrow-right:before {
  background-color: #ffffff;
  color: #4a4a4a;
  content: '\e907';
}
.icon-arrow-left:before {
  background-color: #ffffff;
  color: #4a4a4a;
  content: '\e905';
}

.icon-arrow-left-plain:before {
  color: #4a4a4a;
  content: '\e906';
}

.icon-arrow-right-plain:before {
  color: #4a4a4a;
  content: '\e908';
}

.icon-cross:before {
  color: #00a6ff;
  content: '\e915';
}
.icon-check-ok-white:before {
  content: '\e912';
  color: #4a4a4a;
  border: 1px solid rgba(120, 120, 120, 0.54);
}
.icon-check-ok-cercle:before {
  border-radius: 50%;
  content: '\e911';
  color: #4a4a4a;
}
.icon-message:before {
  content: '\e90c';
}
.icon-calendar:before {
  content: '\e90e';
}
.icon-delete:before {
  color: #4a4a4a;
  content: '\e916';
}
.icon-phone:before {
  content: '\e946';
  color: #4a4a4a;
}

.icon-pencil:before {
  color: rgba(120, 120, 120, 0.54);
  content: '\e937';
}
.icon-edit:before {
  color: rgba(120, 120, 120, 0.54);
  content: '\e91b';
}
.icon-user:before {
  color: #01366b;
  content: '\e949';
}
.icon-exit:before {
  color: #4a4a4a;
  content: '\e91d';
}
.icon-unlock:before {
  color: #4a4a4a;
  content: '\e948';
}

.icon-lock:before {
  color: #00a6ff;
  content: '\e92e';
}
.icon-list:before {
  color: #01366b;
  content: '\e92d';
}

.icon-list-bar:before {
  color: #4a4a4a;
  content: '\e92c';
}
.icon-download:before {
  color: #4a4a4a;
  content: '\e919';
}
.icon-home:before {
  content: '\e927';
}
.icon-recycle:before {
  content: '\e93c';
}
.icon-settings:before {
  color: #01366b;
  content: '\e940';
}
.icon-star-plain:before {
  color: #eddd0c;
  content: '\e945';
}
.icon-logout:before {
  color: #4a4a4a;
  content: '\e92f';
}
.icon-van:before {
  color: #01366b;
  content: '\e94c';
}
.icon-shopping-cart:before {
  color: #4a4a4a;
  content: '\e943';
}

.icon-history:before {
  color: #4a4a4a;
  content: '\e926';
}

.icon-attention:before {
  content: '\e90b';
}

.icon-info-circle:before {
  color: #4a4a4a;
  content: '\e929';
}

.icon-search:before {
  color: rgba(120, 120, 120, 0.54);
  content: '\e93e';
}

.icon-print:before {
  color: #4a4a4a;
  content: '\e93b';
}
.icon-pdf:before {
  color: #4a4a4a;
  content: '\e936';
}
.icon-shop:before {
  color: #4a4a4a;
  content: '\e942';
}
.icon-save:before {
  content: '\e93d';
}
.icon-addtolist:before {
  content: '\e902';
}
.icon-alert:before {
  content: '\e903';
}
.icon-bdc:before {
  content: '\e90d';
}

.icon-cartadd:before {
  content: '\e90f';
}
.icon-cart-arrow:before {
  content: '\e910';
}
.icon-codebar:before {
  content: '\e913';
}
.icon-cold:before {
  content: '\e914';
}
.icon-cross:before {
  content: '\e915';
}

.icon-delivery-back:before {
  content: '\e918';
}

.icon-euro:before {
  content: '\e91c';
}

.icon-fac:before {
  content: '\e91f';
}

.icon-geolocation:before {
  content: '\e923';
}
.icon-heart-outline:before {
  content: '\e924';
}
.icon-heart-plain:before {
  content: '\e925';
}

.icon-password:before {
  content: '\e934';
}
.icon-password-circle:before {
  content: '\e935';
}

.icon-people:before {
  content: '\e938';
}
.icon-pin:before {
  content: '\e939';
}

.icon-ship:before {
  content: '\e941';
}
.icon-star-plain:before {
  content: '\e945';
}
.icon-telephone:before {
  content: '\e946';
}
.icon-time:before {
  content: '\e947';
}
.icon-unlock:before {
  content: '\e948';
}
.icon-user-small:before {
  content: '\e949';
}
.icon-user-circle:before {
  content: '\e94a';
}
.icon-user-outline:before {
  content: '\e94b';
}
.icon-van2:before {
  content: '\e94c';
}
.icon-radio-button--uncheked:before {
  content: '\ea56';
}
.icon-arrow-down:before {
  content: '\e94d';
}
.icon-arrow-down-plain:before {
  content: '\e94e';
}
.icon-arrow-up:before {
  content: '\e94f';
}
.icon-arrow-up-plain:before {
  content: '\e950';
}
.icon-delivery:before {
  content: '\e951';
}
.icon-eye:before {
  content: '\e952';
}
.icon-less:before {
  content: '\e953';
}
.icon-Cancel:before {
  content: '\e969';
}
.icon-check-boat:before {
  content: '\e955';
}
.icon-choix-small:before {
  content: '\e956';
}
.icon-invoice:before {
  content: '\e957';
}
.icon-order-validation:before {
  content: '\e958';
}
.icon-excel:before {
  content: '\e959';
}
.icon-file-small:before {
  content: '\e95a';
}
.icon-froid:before {
  content: '\e95b';
}
.icon-message-small:before {
  content: '\e95c';
}
.icon-moq:before {
  content: '\e95d';
}
.icon-references-facturees:before {
  content: '\e95e';
}
.icon-renew-order:before {
  content: '\e95f';
}
.icon-temperature-small:before {
  content: '\e961';
}
.icon-state:before {
  font-size: 22px;
  content: '\2022';
}
.icon-van2:before {
  content: '\e94c';
}
.icon-File:before {
  content: '\e904';
  color: #00a6ff;
}
.icon-CGV:before {
  content: '\e900';
  color: #4a4a4a;
}
.icon-Check-Circle:before {
  content: '\e909';
}
