.invoice-list__container {
  padding-bottom: 30px;
  padding-top: 20px;

  .invoice-list__breadcrum {
    padding: 2px 26px;
  }

  .invoice-list__content {
    width: calc(100% - 54px);
    margin: 0 auto;

    .invoice-order-list__table-controls {
      padding: 25px 0 10px 0;
      display: flex;
      align-items: center;

      .invoice-order-list__table-controls__button-container {
        display: flex;
        align-items: center;
      }

      .invoice-order-list__button {
        height: 35px;
        padding: 7px 15px;
        cursor: pointer;
        box-shadow: rgb(0, 0, 0) 0 0 1px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        background: #FFFFFF;
        margin-right: 20px;
        border: 1px solid rgb(1, 54, 107);
        i {
          margin-right: 5px;
          font-size: 20px;
        }
      }

      .invoice-order-list__invoice-button {
        background: #2AA952;
        color: #FFF;
        border: 1px solid #2AA952;
      }

      .invoice-order-list__preorder-filter {
        margin-left: 5px;
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li label{
            display: flex;
            align-items: center;
            font-size: 13px;
            i {
              margin-right: 5px;
              &:before {
                font-size: 10px;
              }
            }
            input[type="checkbox"] {
              width: 10px;
              min-width: 10px;
              height: 10px;
              margin-right: 10px;
              margin-left: 0;
              appearance: checkbox;
            }
          }
        }
      }
      .invoice-order-list__search-container {
        position: relative;
        svg {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .search-box {
        height: 30px;
        display: flex;
        align-items: center;
        padding: 15px 10px;
        border-radius: 4.5px;
        border: 0;
        background: #fff;
        min-width: 222px;
        border: 1px solid #01366b;
      }
    }

    .react-table__wrapper {
      border: 0;
      border-radius: 3px;
      max-height: calc(100vh - 345px);
      overflow: auto;
      margin-top: 10px;
      .react-table__row-counter {
        background: #dbdee7;
        color: #01366B;
        font-weight: 600;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar {
        height: 6px;
        width: 6px;
        background-color: #fefefe;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #ccc;
      }

      .invoice-list__type-invoice {
        font-weight: bold;
        color: #01366B;
      }

      .invoice-list__type-credit {
        font-weight: bold;
        color: #CD242E;
      }

      .invoice-list__total-container {
        display: flex;
        color: #01366B;
        white-space: nowrap;
      }

      .invoice-list__action-cell {
        padding-left: 0;
        padding-right: 0;
      }

      .invoice-list__row-action__container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-weight: 600;
        color: #00A6FF;
        fill: #00A6FF;

        .invoice-list__row-action__separator {
          width: 1px;
          height: 30px;
          background: #CCC;
          margin: 0 10px;
        }

        .invoice-list__row-action__action-container {
          display: flex;

          span {
            display: flex;
            align-items: center;
          }

          .invoice-list__row-action__renew {
            cursor: pointer;
            margin-left: 15px;

            svg {
              margin-right: 3px;
            }
          }
        }
      }

      .invoice-list__call-to-action {
        padding-left: 0;
        padding-right: 0;
      }

      .invoice-list__label-container {
        display: flex;
        align-items: center;

        i {
          margin-right: 10px;
        }

        .temp-icon {
          &:before {
            width: 20px;
            height: 20px;
            line-height: 20px;
            font-size: 12px;
          }
        }
      }

      .invoice-list__credit-label {
        font-size: 12px;
        margin: 0 15px;
        background: #CD242E;
        border-radius: 20px;
        color: #FFF;
        white-space: nowrap;
        padding: 5px;
        position: relative;
        z-index: 1;
      }

      .invoice-list__expander {
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #CCC;
        border-radius: 20px;
      }

      .invoice-list__credit-cell {
        padding: 20px;
        cursor: initial;

        .invoice-list__credit-table {
          width: 1px;
          table-layout: auto;
          margin-top: 10px;
          .invoice-list__credit-item {
            border-bottom: none;
            position: relative;

            td {
              white-space: nowrap;
              &:first-child {
                padding-left: 100px;
                position: relative;
                &:before {
                  content: "";
                  position: absolute;
                  border-bottom: 1px solid #65676B;
                  border-left: 1px solid #65676B;
                  width: 30px;
                  height: 100%;
                  left: 60px;
                  top: -17px;
                  z-index: 0;
                }
              }
            }
          }
        }

        .invoice-list__credit-price {
          color: red;
        }
      }

      table {
        border: 0;
        box-shadow: none;

        thead {
          background: #01366B1A;
          color: #01366B;
          position: relative;
          z-index: 2;

          th {
            background: #dbdee7;
            white-space: nowrap;
          }
        }

        tbody {
          background: #FFF;

          .icon-fac {
            font-size: 29px;
            cursor: pointer;
          }

          tr {
            cursor: pointer;

            &:hover {
              background: rgb(249, 250, 251);
            }

            td {
              padding: 9px 24px;
            }
          }

          .react-table__no-result {
            cursor: default;
          }
        }
      }
    }
  }
}
