.action-bloc__add-favorie-item {
  border: 1px solid #54bafc;
}

.action-bloc__favorie-add {
  clear: both;
  text-align: center;
  width: 100%;
  margin: 40px 0 20px 0;
  padding-right: 10px;
}

.action-bloc__add-favorie-item {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #54bafc;
  border-radius: 20px;
}

.action-bloc__add-favorie-item i {
  font-size: 18px;
  font-weight: 100;
  display: inline-block;
  vertical-align: middle;
  padding-right: 8px;
}

.action-bloc__add-favorie-item span {
  font-weight: 700;
  letter-spacing: 1.06px;
  font-size: 12px;
  display: inline-block;
  vertical-align: middle;
  text-transform: uppercase;
  cursor: pointer;
  color: #00a6ff;
}

.action-bloc__add-favorie-item {
  margin-bottom: 20px;
}
