.page-order-from-csv {
  padding: 20px;
}
.csv-title {
    margin-bottom: 40px;
    text-align: center;
    color: #01366B;
}

.orderFromCsv__errors {
    width: 50%;
    min-width: 300px;
    margin: 0 auto;
}
.csv-col {
  display:flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto 40px auto;
}
.csv-description {
  width: 100%;
  padding-top: 0;
  padding-right: 30px;
  p {
    margin: 0;
  }
  b {
    color: #01366B;
  }
  a {
    font-weight: bold;
    color: #01366B;
  }
}
.csv-form {
  width: 100%;
  text-align: center;

  .csv-form-container {
    width: 100%;
    min-width: 300px;
    max-height: 267px;
    min-height: 175px;
    position: relative;
    border: 1px solid #01366b;
    border-radius: 20px;
    overflow: auto;

    .csv-file-input-container {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 2;

      .csv-file-input, .csv-fake-file-input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
      }
      .csv-file-input {
          opacity: 0;
          cursor: pointer;
      }
    }
    .csv-message-input,
    .csv-message-input-2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
      position: absolute;
      width: 100%;
      background: #fff;
      z-index: 1;
      svg {
          width: 50px;
          height: 50px;
          margin-bottom: 10px;
          fill: #4a4a4a;
      }
    }
    .csv-message-input-2 {
      z-index: 3;
      span {
        margin-bottom: 20px;
      }
      .bloc-btn {
        a:first-child {
          margin-right: 10px;
        }
      }
    }
    .is-loading {
      position: absolute;
      height: 100%;
      width: 100%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 6;
    }
    .file-imported,
    .file-imported-error {
      position: absolute;
      height: 100%;
      width: 100%;
      background: #fff;
      z-index: 5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      svg {
          width: 50px;
          height: 50px;
          margin-bottom: 10px;
          fill: #4a4a4a;
      }
    }
    .file-imported-error {
      z-index: 6;
      color: red;
      svg {
        fill: red;
      }
    }
  }
  .bloc-button-flex {
    display: flex;
    a:first-child {
      margin-right: 10px;
    }
  }
}
.csv-how-to {
    width: 50%;
    margin: 0 auto;
}

.bloc-btn {
  text-align: center;
  width: 100%;
  a {
    font-weight: bolder;
  }
  &.hidden {
    display: none;
  }
}
.csv-result {
  display: flex;
  align-items: flex-start;
  width: 90%;
  margin: 0 auto 30px auto;
  .nb-refs {
    margin-bottom: 0px;
    min-height: 40px;
    padding-left: 7px;
  }
  .react-table__wrapper {
    margin-top: 0;
    margin-bottom: 0;
  }
  .csv-import-content {
    max-height: 230px;
    overflow-y: auto;
    padding: 10px;
  }
}
.csv-import {
  width: 50%;
  padding-right: 10px;
  .csv-content {
    max-height: 245px;
    overflow-y: auto;
    padding: 10px;
    &.error-max {
      border-radius: 20px;
      background: #fff;
      border: 1px solid red;
    }
  }

}
.csv-success {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
  .nb-refs-not {
    color: red;
  }
  .nb-refs-success {
    color: green;
    text-align: left;
  }
}
.csv-import-limit {
  width: 50%;
}
.nb-refs-orange {
  color: orange;
  text-align: left;
}
.csv-rapport {
  width: 100%;
  display: flex;
  .csv-content {
    width: 50%;
    max-height: 245px;
    overflow-y: auto;
    padding: 10px;
  }
  .text-rapport {
    width: 50%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    span {
      display: block;
      margin-bottom: 10px;
    }
    .download-rapport {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      a {
        font-size: 10px;
      }
      a:not(:last-child) {
        margin-right: 10px;
      }
    }
    .go-to-cart {
      margin-top: 20px;
      display: flex;
      justify-content: center;
      a:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
.green {
  color: green;
}
.orange {
  color: orange;
}
@media (max-width: 767px) {
  .csv-col {
    flex-direction: column;
  }
  .csv-description {
    padding-right: 0;
    margin-bottom: 20px;
  }
  .csv-import {
    width: 100%;
    padding-right: 10px;
  }
  .csv-rapport {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .csv-rapport .csv-content {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
    padding: 0;
    margin-bottom: 20px;
  }
  .csv-rapport .text-rapport {
    width: 100%;
    padding: 0 0px;
  }
  .csv-rapport .text-rapport .download-rapport {
    margin-top: 20px;
    display: block;
    a:not(:last-child) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
}


