.AggridContainer {
  height: calc(100vh - 210px); /* for browsers that do not support Custom Properties */
  height: calc((var(--vh, 1vh) * 100) - 210px); /* use custom vh property to prevent vertical shift on mobile browsers */
  width: 100%;
}

.ProductCardContainer {
  float: right;
  height: calc(100vh - 176px); /* for browsers that do not support Custom Properties */
  height: calc((var(--vh, 1vh) * 100) - 176px); /* use custom vh property to prevent vertical shift on mobile browsers */
  width: 450px;
  padding: 20px;
  overflow: auto;
  margin-top: 55px;
  background: #FFF;
}
