.OrderRating__App {
  position: relative;
  margin: 30px 0;
}

.OrderRating__errors {
  width: 50%;
  min-width: 300px;
  max-width: 500px;
  margin: 0 auto 15px auto;
}

.OrderRating__overlay {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.7);
  
  svg {
    width: 50px;
    height: 50px;
  }
}

.OrderRating__title, .OrderRating__stars-container, .OrderRating__comment-container {
  margin-bottom: 20px;
}

.OrderRating__stars-container {
  svg {
    width: 30px;
    height: 30px;
    margin: 0 5px;
    cursor: pointer;
  }
}

.OrderRating__comment-container {
  textarea {
    width: 50%;
    min-width: 300px;
    max-width: 500px;
    min-height: 200px;
    padding: 10px;
    border-radius: 10px;
  }

  textarea:focus {
    outline: 0;
    border: 1px solid;
  }
}